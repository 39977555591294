:root {
  --bluecolor: #2E3191;
  --redcolor:  #EC1B23;
  --greencolor: #006E3A;
  --txtcolor: #2c3e50;
  --bodybgcolorgrey: #333;
  --bodybgcolorwhite: whitesmoke;
  --txtcolorwhite: whitesmoke;
}

body{
  color: #2c3e50;
}
.blue-color{
  color: var(--bluecolor);
}

.red-color{
  color: var(--redcolor);
}


.App {
  position: relative;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.banner-carousel {
  position: relative;
  z-index: 1;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
}

.sticky-navbar {
  background-color: var(--bluecolor)!important;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.transparent-navbar {
  background-color: transparent !important;
  transition: background-color 0.3s ease;
}






/* Optional: Custom styles for the carousel */
.carousel-item {
  height: 600px;
}

.carousel-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.container-overflow-width{
  width:100%,;
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  margin: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans";
  overflow-x: hidden;
}

/* Font Family */
body {
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: "Open Sans";
}

/* Font Sizes */
h1 {
  font-size: 36px;
  line-height: 1.2;
}

h2 {
  font-size: 24px;
  line-height: 1.2;
}

h3 {
  font-size: 18px;
  line-height: 1.2;
}


/* Font Weights */
h1, h2, h3 {
  font-weight: 700 !important;
}


/* Text Color */
body {
  color: var(--txtcolor);
}

h1, h2, h3 {
  color: #222;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover {
  color: var(--redcolor);
}


p{
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  color:var(--txtcolor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.white-text {
  color: #fff;
}

.banner {
  position: relative;
  background-image: linear-gradient(89.82deg, #1E1E1E -3.88%, rgba(0, 0, 0, 0) 100%), url('./images/church.jpg'); /* Add your gradient here */
  height: 520px; /* Adjust as needed */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Choose a color that contrasts well with the gradient */
  background-size: cover; /* Ensure the background image covers the entire container */
}

.nav-item-style {
  font-weight: 700; /* Or any other font weight value */
  color: whitesmoke;
}

/*NAV LINKS */
/* NavBarComponent.css */

.sticky-navbar {
  background-color: var(--bluecolor) !important;
  transition: background-color 0.3s ease;
}

.transparent-navbar {
  background-color: transparent !important;
  transition: background-color 0.3s ease;
}

.nav-link-custom {
  font-weight: 500;
  color: white; /* Default color */
  text-decoration: none;
  /*transition: color 0.3s ease;*/
}

.nav-link-custom:hover,
.nav-link-custom:focus,
.nav-link-custom:active {
  color: red; /* Change color on hover, focus, and active */
}

/*
.nav-link-custom::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--redcolor);
  transition: width 0.3s ease;
}

.nav-link-custom:hover::after,
.nav-link-custom:focus::after {
  color: var(--redcolor);
  width: 100%;
}

.nav-link-custom.active::after {
  width: 100%;
}

*/

.container_player {
  display: flex; /* Enable Flexbox for the container */
  width: 100%;
}

.fixed-width_player {
  
  width: 100px; /* Adjust fixed width as needed */
}

.fixed-width_player_timer {
  padding-top: 8px;
  width: 30px; /* Adjust fixed width as needed */
  font-size: 12px;
}
.fixed-width_player_volume {

  width: 25px; /* Adjust fixed width as needed */
  
  
}

.fixed-width_player_audio {
  padding-top: 15px;
  width: 70px; /* Adjust fixed width as needed */
  margin-right: 30px;
}

.flexible_player {
  padding-top: 15px;
  flex: 1; /* Allow this div to flex and fill remaining space */
  margin-right: 20px;
}
.sermontitle{
  font-weight: 700;
  font-size: 16px;
}
.grouplogo{
  padding: 10px;
  
}
.blog_info p, .event-date p, .event-info p{
  
  margin-bottom: 0px;
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-date{
  background:#373232;
  color: whitesmoke;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.event-date .day{
  font-size: 24px;
  font-weight: 700;
}

.event-date .month{
  font-size: 14px;
  
}

.overlay-home {
  position: absolute; /* Overlaps the background element */
  top: 0; /* Position at the top */
  left: 0; /* Position at the left */
  width: 100%; /* Span the full width of the background */
  height: 100%; /* Span the full height of the background */
  background-color: #373232; /* Semi-transparent black background */
  opacity: 0.8;
  /* Adjust opacity value (between 0 and 1) for desired transparency */
  display: flex; /* Allow centering of text content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  min-height: 400px;
}

.overlay-home-countdown {
  position: absolute; /* Overlaps the background element */
  top: 0; /* Position at the top */
  left: 0; /* Position at the left */
  width: 100%; /* Span the full width of the background */
  height: 100%; /* Span the full height of the background */
  background-color: #373232; /* Semi-transparent black background */
  opacity: 0.8;
  /* Adjust opacity value (between 0 and 1) for desired transparency */
  display: flex; /* Allow centering of text content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100px;
}

.bg-verse {

}

.bg-beforefooter {
  background-image: url('./images/verse/bg-verse.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100px;
  max-height: 100px !important;
  position: relative;
}

.activity p{
  margin-bottom: 0px; 
}

.activity .topic , .verse-annouce .topic{
  font-weight: 700;

}
.verse-annouce{
  background-color: var(--redcolor);
  height: 100px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 0px;
  padding: 25px 20px;
  color: whitesmoke;
}

.verse-annouce p{
  padding-bottom: 0px;
}

.annoucelist, .annoucelist ol {
  text-align: left;
}

.mb-0 {
 margin-bottom: 0px;
}
.mb-10 {
  margin-bottom: 10px;
 }
 

/*helpers*/

.pl2{
  padding-left: 20px !important;
}
.pl3{
  padding-left: 30px !important;
}

.alignleft{
  text-align: left !important;
}

.alignright{
  text-align: right !important;
}

.f12{
  font-size: 12px;
}

.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; /* or any other width value */
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.flex-row-month {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 5px;
}

.flex-row-xs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.small-heading {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  text-align: left;
}

.red-band{
  text-align:left; 
  padding-left: 5%;
  padding-right: 5%;  
  padding-top:10px; 
  color:whitesmoke;
  font-weight:600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; /* or any other width value */
}

.nobg{
  background-color: transparent !important;
  background: none !important;
  background-image: none !important;
}

.list-marker {
  font-weight: bold;

}
/* footer */
.footer-heading {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  text-align: left;
}

