.custom-list {
    width: 100%; 
    text-align: left;
    padding-left: 0;
    }
    .custom-list li {
      display: inline-block;
      width: calc(50% - 20px);
      position: relative;
      margin-bottom: 0px;
      padding-left: 20px; }
      .custom-list li:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px solid #3b5d50;
        position: absolute;
        left: 0;
        top: 15px; }

        @media only screen and (max-width: 990px) {
          .custom-list li {
           display: block;
          }
        }
        