.header-container {
  position: relative;
  width: 100%;
  height: 350px;
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
}

.gradient-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #040000 0%, rgba(255, 255, 255, 0) 100%);
}

.header-text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.2;
  color: #ffffff;
}

.clear-headertext{
  font-size: 2.105rem;
  letter-spacing: .03em;
}

@media (max-width: 768px) {
  .header-container {
    height: 300px;
  }
  
  .header-text {
    font-size: 3rem;
  }

  .clear-headertext {
    font-size: 1.474rem!important;
  }
}

@media (max-width: 576px) {
  .header-text {
    font-size: 2rem;
  }
}





.clear-header {
  margin-top: 100px;
  height: 140px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.page-title {
  font-size: 2rem;
  text-align: left;
  font-weight: bold;
  color: #343a40;
}

.breadcrumb-wrapper {
  color: #6c757d;
  font-size: 0.875rem;
}

/* Responsive styles */
@media (max-width: 991.98px) {
  .page-title {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .breadcrumb-wrapper {
    text-align: center;
  }
}

/* For very small screens */
@media (max-width: 575.98px) {
  .page-title {
    font-size: 1.5rem;
  }
}