/* BannerCarouselComponent.css

.carousel-overlay {
    position: relative;
  }
  
  .carousel-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }
  
  .carousel-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  
  .carousel-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: whitesmoke;
    text-align: center;
  }
  
 */
 /* Carousel container styles */
.carousel-container {
  position: relative;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Each carousel item */
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.carousel-item.active {
  opacity: 1;
}

/* Overlay that covers the entire carousel item */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1); /* Semi-transparent overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.overlay h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: white;
}

/* Custom content */
.custom-content {
  margin-top: 20px;
}

.custom-content p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.custom-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custom-button:hover {
  background-color: #0056b3;
}

/* Carousel controls */
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
}

.carousel-control.left {
  left: 20px;
}

.carousel-control.right {
  right: 20px;
}

.carousel-control:hover {
  color: #f0f0f0;
  transform: translateY(-50%) scale(1.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .overlay h1 {
    font-size: 2rem;
  }

  .custom-content p {
    font-size: 1rem;
  }

  .custom-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .carousel-control {
    font-size: 1.5rem;
  }
}
