/* Custom scrollbar styles for Webkit browsers */
.overflow-auto::-webkit-scrollbar {
    width: 5px;
  }
  
  .overflow-auto::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .overflow-auto::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
  }
  
  /* Smooth transitions for menu items */
  .menu-item {
    transition: background-color 0.2s ease;
  }
  
  .submenu-item {
    transition: transform 0.2s ease;
  }