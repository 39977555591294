.search-container {
    display: flex;
    justify-content: left;
    border: 1px solid rgba(169, 184, 195, 0.4);
    padding: 10px;
    margin-top: 20px;
}

.search-form {
    width: 100%;
    max-width: 100%;
    margin-right: 20px;
}

.btn-holder {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.tab-button {
    background-color: transparent;
    border: none;
    color: var(--txtcolor);
    text-decoration: none;
    font-weight: 600;
}

.tab-button:hover,
.tab-button:focus {
    text-decoration: none;
    background-color: transparent;
    box-shadow: none;
}

.tab-button:active::after,
.tab-button.active::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--redcolor);
    position: relative;
    top: 20px;
}

.search-wrapper {
    display: flex;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input {
    flex: 1;
    border: none;
    padding: 10px;
    outline: none;
}

.search-input:focus {
    box-shadow: none;
}

.search-button {
    padding: 10px 20px;
    font-size: 13px;
    border: none;
    background-color: var(--bluecolor);
}

@media (max-width: 575.98px) {
    .search-button {
        padding: 10px;
    }
}
/*event list section */

.date-tag{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    text-align: center;
}

.month{
    font-size: 14px;
    text-transform: uppercase;
    color: var(--txtcolor);
}

.day{
    font-size: 24px;
    line-height: 1.42;
    font-weight: bold;
    color: var(--txtcolor)
}

.text-with-separator {
    width: 100%;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
}

.text-with-separator::after {
    background-color: rgba(169, 184, 195, 0.9);
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 50%; /* adjust to mid-height */
    left: 160px; /* adjust to desired offset */
    width: 100%; /* adjust to desired width */
    transform: translateY(-50%); /* center vertically */
}

.separator {
  background-color: rgba(169, 184, 195, 0.9);
  display: block;
  height: 1px;
}

/*date picker*/
.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker-input{
  font-size: 26px;
  width: 100%;
  max-width: 50px;
}

.react-calendar {
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1.1rem;
}

.react-calendar__tile {
  padding: 1em 0.5em;
}

/*month calendar*/
.month-calendar {
  max-width: 100%;
  padding: 20px;
}
  
.flex-row-month {
  display: flex;
  justify-content: left;
  align-items: center;
}
  
.date-picker-container {
  display: flex;
  justify-content: left;
}

.react-datepicker-wrapper {
  width: 250px;
  max-width: 100%;
}
  
.react-datepicker__input-container input {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
  
.react-datepicker__input-container input:focus {
  outline: none;
}
  
.calendar-grid {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  /*background-color: rgba(169, 184, 195, 0.4);*/
  border: none;
}
  
.calendar-day {
  /*background-color: #fff;*/
  aspect-ratio: 9 / 16;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
  font-size: 1vw;
  position: relative;
}

.calendar-header {
  font-weight: bold;
  justify-content: left;
  align-items: center;
  border: none;
  /*background-color: #fff;*/
  display: flex;
  padding: 5px;
  font-size: 1.5vw;
}
  
.day-number {
  font-size: 2vw;
  font-weight: 600;
}
  
.event-dot {
  background-color: var(--bluecolor);
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-top: 5px;
  cursor: pointer;
}
  
.event-name-container {
  font-size: 0.8vw;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
  
.event-name {
  font-weight: bold;
}
  
.event-time {
  margin-left: 5px;
  color: #666;
}
  
.event-details-tooltip {
  max-width: 300px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
  
.event-details-tooltip img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}
  
.event-details-tooltip .event-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  cursor: pointer;
}
  
.event-details-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 600px;
  z-index: 1;
}
  
.event-details {
  margin-bottom: 10px;
}
  
.event-details .event-name {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
  
.nav-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--txtcolor);
  padding: 0 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
  
.nav-button:hover,
.nav-button:focus,
.nav-button:active {
  background-color: transparent;
  color: var(--txtcolor);
  border: none;
  outline: none;
  box-shadow: none;
}
  
@media (max-width: 768px) {
  .calendar-grid {
    border: none;
    background-color: transparent;
  }
  .calendar-header,
  .calendar-day {
    font-size: 2vw;
  }

  .day-number {
    font-size: 2.4vw;
  }

  .react-datepicker__input-container input {
    font-size: 1.2rem;
  }

  .calendar-header {
    font-size: 3vw;
  }

  .nav-button {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  .event-details-container {
    bottom: -200px;
    width: 90%;
  }

  .event-name-container {
    display: none;
  }

  .event-dot {
    display: block;
  }
}
  
@media (max-width: 480px) {
  .calendar-header,
  .calendar-day {
    font-size: 3vw;
  }

  .day-number {
    font-size: 3.6vw;
  }

  .react-datepicker__input-container input {
    font-size: 1rem;
  }

  .nav-button {
    font-size: 0.7rem;
    padding: 5px;
  }

  .event-details-container {
    bottom: -250px;
  }
}
  
@media (min-width: 769px) {
  .event-dot {
    display: none;
  }

  .event-name-container {
    display: block;
  }
}
/*event list*/
.event-card{
  margin-bottom: 20px;
}

/* event name link */
.event-name-link {
  color: var(--bs-dark-text);
  cursor: pointer;
  display: inline; /* Change to inline */
  position: relative; /* for pseudo-element */
  transition: color 0.3s; /* smooth text color transition */
}

.event-name-link:hover,
.event-name-link:focus {
  color: var(--redcolor); /* text color on hover/focus */
}

.event-name-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: var(--bs-dark);
  opacity: 0;
  transition: opacity 0.3s, width 0.3s; /* Added width transition */
}

.event-name-link:hover::after,
.event-name-link:focus::after {
  opacity: 1; /* show line on hover/focus */
}