
.bible-verse-box{
    padding-top: 10%;
    padding-right: 30%;
    padding-left: 20%;
    padding-bottom: 10%;
    background-image: url('../../../images/verse/bg-verse.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.bible-verse-box .overlay-home {
  position: absolute; /* Overlaps the background element */
  top: 0; /* Position at the top */
  left: 0; /* Position at the left */
  width: 100%; /* Span the full width of the background */
  height: 100%; /* Span the full height of the background */
  background-color: #373232; /* Semi-transparent black background */
  opacity: 0.8;
  /* Adjust opacity value (between 0 and 1) for desired transparency */
  display: flex; /* Allow centering of text content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  min-height: 400px;
}
/* Default font size */
.bible-verse-box h1 {
    font-size: 2.5rem;
  }
  
  .bible-verse-box p {
    font-size: 1.5rem;
  }
  
  /* Font size adjustments for smaller screens */
  @media (max-width: 576px) {
    .bible-verse-box h1 {
      font-size: 1.5rem;
    }
  
    .bible-verse-box p {
      font-size: 1rem;
    }
  }
  
.verse-bg{
  padding: 3rem 0 2rem;
  position: relative;
  background-color: #e3e6ec;
  background-image: url('../../../images/verse/overlay.svg'), linear-gradient(45deg, #eff3f9 0%, #eff3f9 100%);
  background-size: cover;
  z-index: 0;}