.we-help-section {
    padding: 7rem 0; }
    .we-help-section .imgs-grid {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: (1fr)[27];
      grid-template-columns: repeat(27, 1fr);
      position: relative; }
      .we-help-section .imgs-grid:before {
        position: absolute;
        content: "";
        width: 255px;
        height: 217px;
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-transform: translate(-40%, -40%);
        -ms-transform: translate(-40%, -40%);
        transform: translate(-40%, -40%);
        z-index: -1; }
      .we-help-section .imgs-grid .grid {
        position: relative; }
        .we-help-section .imgs-grid .grid img {
          border-radius: 20px;
          max-width: 100%; }
        .we-help-section .imgs-grid .grid.grid-1 {
          -ms-grid-column: 1;
          -ms-grid-column-span: 18;
          grid-column: 1 / span 18;
          -ms-grid-row: 1;
          -ms-grid-row-span: 27;
          grid-row: 1 / span 27; }
        .we-help-section .imgs-grid .grid.grid-2 {
          -ms-grid-column: 19;
          -ms-grid-column-span: 27;
          grid-column: 19 / span 27;
          -ms-grid-row: 1;
          -ms-grid-row-span: 5;
          grid-row: 1 / span 5;
          padding-left: 20px; }
        .we-help-section .imgs-grid .grid.grid-3 {
          -ms-grid-column: 14;
          -ms-grid-column-span: 16;
          grid-column: 14 / span 16;
          -ms-grid-row: 6;
          -ms-grid-row-span: 27;
          grid-row: 6 / span 27;
          padding-top: 20px; }
  
  .custom-list {
    width: 100%; }
    .custom-list li {
      display: inline-block;
      width: calc(50% - 20px);
      margin-bottom: 12px;
      line-height: 1.5;
      position: relative;
      padding-left: 20px; }
      .custom-list li:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px solid #3b5d50;
        position: absolute;
        left: 0;
        top: 8px; }
  