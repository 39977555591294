.welcome-text {
  text-align: left;
  padding-top: 80px;
  color: #222;
}

.welcome-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
}

.welcome-message {
  font-size: 16px;
  margin-bottom: 1rem;
}

.welcome-signature {
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 18px;
}

.welcome-position {
  font-size: 16px;
}

.pastor-img {
  width: 80%;
  transition: opacity 0.3s ease-in-out;
}

.pastor-img.loading {
  opacity: 0;
}

.pastor-img.loaded {
  opacity: 1;
}

/* Adjustments for small screens */
@media (max-width: 576px) {
  .welcome-title {
    font-size: 32px;
  }

  .welcome-message {
    font-size: 14px;
  }

  .welcome-signature {
    font-size: 16px;
  }

  .welcome-position {
    font-size: 14px;
  }

  .pastor-img {
    width: 100%;
    height: auto;
  }
}

 


    blockquote {
      font-size: 1.25rem;
    font-style: italic;
    color: #34495e;
    line-height: 1.6;
    text-align:center;
  }
  
  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    
  }
  blockquote p {
    display: inline;
  }






  /* Add these styles to your Welcome.css file */

.feature {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: white;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature .icon {
  font-size: 2rem;
  margin-bottom: 15px;
}

.feature .icon svg {
  transform: rotate(360deg);
  transition: transform 0.8s ease;
}

.feature:hover .icon svg {
  transform: rotate(0deg);
}

.feature h3 {
  margin-bottom: 10px;
  font-size: 1.25rem;
}

.feature p {
  margin: 0;
  opacity: 0.9;
  color:  whitesmoke;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .feature {
    padding: 15px;
  }
  
  .feature .icon {
    font-size: 1.5rem;
  }
  
  .feature h3 {
    font-size: 1.1rem;
  }
}