/*.motto-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.blockquote {
  padding: 60px 80px 40px;
  position: relative;
  text-align: center;
}

.blockquote p {
  font-family: "Utopia-italic", serif;
  font-size: 35px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  color: #333;
}

.quote-icon {
  font-size: 60px;
  color: red;
  position: absolute;
  left: -20px;
  top: -10px;
  z-index: 0;
}

.blockquote::after {
  content: "";
  top: 20px;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  border-bottom: 3px solid red;
  height: 3px;
  width: 200px;
}

.motto {
  font-style: italic;
  font-size: 24px;
  margin: 0;
}

@media (max-width: 576px) {
  .blockquote {
    padding: 40px 20px;
  }

  .blockquote p {
    font-size: 24px;
  }

  .quote-icon {
    font-size: 40px;
    left: -10px;
    top: -5px;
  }

  .blockquote::after {
    top: 15px;
    width: 150px;
    margin-left: -75px;
  }

  .motto {
    font-size: 18px;
  }
}


*/









.motto-wrapper {
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  min-height: 40vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.motto-content {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s ease-out;
}

.motto-content.visible {
  opacity: 1;
  transform: translateY(0);
}

.quote-container {
  padding: 2rem;
  position: relative;
}

.cross-icon {
  color: var(--redcolor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  opacity: 0.8;
}

.motto-text {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.4;
  color: #2c3e50;
  margin: 1.5rem 0;
  text-transform: capitalize;
  font-family: 'Times New Roman', serif;
}

.quote-icon {
  color: var(--redcolor);
  opacity: 0.6;
  transition: all 0.3s ease;
}

.quote-left {
  font-size: 1.5rem;
  position: absolute;
  left: -15px;
  top: 0;
}

.quote-right {
  font-size: 1.5rem;
  position: absolute;
  right: -15px;
  bottom: 0;
}

.motto-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.divider-line {
  height: 2px;
  width: 100px;
  background: var(--redcolor);
  opacity: 0.3;
}

.divider-icon {
  color: var(--redcolor);
  font-size: 1rem;
  margin: 0 1rem;
  opacity: 0.6;
}

.motto-subtitle {
  font-size: 1.2rem;
  color: var(--redcolor);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .motto-text {
    font-size: 1.5rem;
  }

  .quote-left,
  .quote-right {
    font-size: 1.2rem;
  }

  .cross-icon {
    font-size: 2rem;
  }

  .divider-line {
    width: 60px;
  }

  .motto-subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .motto-text {
    font-size: 1.2rem;
  }

  .quote-container {
    padding: 1.5rem;
  }

  .divider-line {
    width: 40px;
  }
}

/* Optional: Add hover effects */
.quote-container:hover .quote-icon {
  opacity: 1;
  transform: scale(1.1);
}

.quote-container:hover .cross-icon {
  opacity: 1;
  transform: rotate(360deg);
  transition: transform 0.8s ease;
}
