.control-size{
    font-size: 12px !important;
}

.text_wrapper {
    /* Set a width for your text container */
    width: 100%;
  
    /* Prevent line breaks within words */
    white-space: nowrap;
  
    /* Hide overflowing content */
    overflow: hidden;
  
    /* Apply ellipsis on overflow */
    text-overflow: ellipsis;
}
 
  